import ReactGA from 'react-ga4';

export const logEvent = (category, action, label) => {
  if (category && action) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
};