import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; 

function HeaderNav({ user }) {
    

  return (
    <div>
        <Link to="/" >
            <img className="logo_inside" src="/logo.png" alt="Logo" height={51} />
        </Link>
        {user && (
         <div>user</div>
        )} 
    </div>
  );
}

export default HeaderNav;
