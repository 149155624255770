import React, { useState, useEffect, useRef } from 'react';
import ReportPopup from './ReportPopup'; // Adjust the path as needed 
import { Oval } from 'react-loader-spinner'; 
import { FaUserCircle } from 'react-icons/fa'; // Import the default profile icon from React Icons
import { BASE_URL} from '../contexts/Constants.js';
 
const RandomChat = ({ user, onBack, socket }) => {
  const [randomUser, setRandomUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);  
  const [showReportPopup, setShowReportPopup] = useState(false);
  const popupRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false); 
  const [areYouTyping, setareYouTyping] = useState(false);  
  const typingTimeoutRef = useRef(null);  
  const is_typing = useRef(null);  
  const [showImageInput, setShowImageInput] = useState(false);
  const [confirmDisconnect, setConfirmDisconnect] = useState(false);
  const [hiddenInputs, setHiddenInputs] = useState([]);
  const [userMessageCount, setUserMessageCount] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');

  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste action
    setAlertMessage('Pasting text is disabled.');  
    setTimeout(() => {
      setAlertMessage('');
    }, 5000);
  };

  const handleImageChange = (imageReqId) => (e) => {
    const file = e.target.files[0];
 
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Compress the image to 80x80 using an HTML canvas element
        const image = new Image();

        image.onload = () => {
          const maxSize = 200;
          let width = image.width;
          let height = image.height;
  
          // Calculate the aspect ratio
          const aspectRatio = width / height;
  
          // Determine the new dimensions while maintaining the aspect ratio
          if (width > height) {
            width = maxSize;
            height = maxSize / aspectRatio;
          } else {
            height = maxSize;
            width = maxSize * aspectRatio;
          }
  
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
  
          // Draw the loaded image onto the canvas with the new dimensions
          ctx.drawImage(image, 0, 0, width, height);
   
          // Convert the image to base64 code
          const base64Image = canvas.toDataURL('image/jpeg'); // Adjust quality as needed
          
          // You can add some logic to send this base64Image to the server if needed
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              name: user.name,
              text: base64Image,
              type: 1 
            }
          ]);

          socket.emit('randomChatMessage', { id: randomUser.id, socket: randomUser.socket, name: user.name,  text: base64Image, type: 1}); 

          setHiddenInputs(prev => [...prev, imageReqId]); 
        };

        image.src = reader.result;
      };

      reader.readAsDataURL(file);
    }
  };
 

  const handleShowRequestImage = () => {
    // Code to show the image request to the user.
    setShowImageInput(true);
  };

  const handleRequestImage = () => {
    if (userMessageCount < 4) {
      // Alert or display a message that more messages are needed    
      setAlertMessage("You need to chat a little bit before requesting an image.");
      // Clear the message after 5 seconds
      setTimeout(() => {
        setAlertMessage('');
      }, 5000);
      return;
    }

    socket.emit('randomChatMessage', { id: randomUser.id, socket: randomUser.socket, name: user.name, text: "Requested image...", type: 2 });
    setMessages((prevMessages) => [...prevMessages, { name: user.name, text: "Requested image...", type: 0 }]);
  }; 
  
  useEffect(() => {
    initiateRandomUser();

    socket.on('randomReq', handleShowRequestImage);
    // Cleanup function to remove listener when component unmounts
    return () => {
      socket.off('randomReq', handleShowRequestImage);
    };
  }, [socket]);
 
  useEffect(() => {
     // Listen for 'typing' event
    socket.on('randomTyping', (id) => {
      if (id === randomUser.id) {
        setIsTyping(true);  
        clearTimeout(is_typing.current);
        is_typing.current = setTimeout(function() {
          setIsTyping(false);
        }, 6000);
      }
    });
    
    // Listen for randomUser event
    socket.on('randomUser', handleRandomUser);
    socket.on('randomChatMessage', (msg) => {
      setMessages((prevMessages) => [...prevMessages, msg]);
      setIsTyping(false);  
      setUserMessageCount(userMessageCount=>userMessageCount + 1);
    });
    
    // Update the socket.on('disconnectUsr', () => { ... }) handler
    socket.on('randomDisconnect', () => {
      setRandomUser(null);
      setMessages([]); 
      // Add a message with a "New Chat" button
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          name: '',
          text: 'User disconnected from chat.',
          type: 3
        },
      ]);

      // Disable input fields and buttons (except for "Report User" and "New Chat")
      setShowImageInput(false);
      setMessage('');
      setIsTyping(false); 
    });  

    // Update the socket.on('disconnectUsr', () => { ... }) handler
    socket.on('disconnect', () => {
      setRandomUser(null);
      setMessages([]); 
      // Add a message with a "New Chat" button
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          name: '',
          text: 'User disconnected from chat.',
          type: 3
        },
      ]);

      // Disable input fields and buttons (except for "Report User" and "New Chat")
      setShowImageInput(false);
      setMessage('');
      setIsTyping(false); 
      setUserMessageCount(0);
    });  
    
    // Cleanup function to remove listener when component unmounts
    return () => {
      socket.off('randomUser', handleRandomUser);
      socket.off('randomChatMessage');
      socket.off('randomDisconnect');
      socket.off('randomTyping');
    };
  }, [socket,randomUser]);

  const initiateRandomUser = () => {
    setLoading(true);
    setTimeout(function() {
       socket.emit('randomUser', user);
    }, 1000);
  };

  const handleRandomUser = (randomUser) => { 
    setRandomUser(randomUser);
    setLoading(false);
    setMessages([]); // Clear previous chat messages when a new user is found

      // Add a message with a "New Chat" button
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          name: '',
          text: 'Connected to a random user, say hi!',
          type: 4
        },
      ]);
  };

  const handleNewChat = () => { 
     // User confirmed, proceed to disconnect
     if(randomUser!=null) {
        socket.emit('randomDisconnect', true);
      }
      setRandomUser(null);
      setMessages([]); 
      setLoading(true); 
      setUserMessageCount(0); 
      initiateRandomUser();
      // Reset the confirmation state
      setConfirmDisconnect(false);
  };
  
  const handleDisconnect = () => {
    if (!confirmDisconnect) {
      // Show the confirmation dialog
      setConfirmDisconnect(true);
    } else {
      handleNewChat();
    }
  };


  const handleSendClick = () => {
    if (message.trim().length > 0) {
      socket.emit('randomChatMessage', { id: randomUser.id, socket: randomUser.socket, name: user.name, text: message, type:0 });
      
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          name: user.name,
          text: message
        }
      ]);

      setMessage('');
      setareYouTyping(false); 
    }
  };
  
  const handleReportPopup = () => {
    setShowReportPopup(!showReportPopup);
  };

  // Function to emit typing event
  const handleTyping = () => {
    if (!areYouTyping) {
      socket.emit('randomTyping', {to_id:randomUser.id, users_id: user.id}); // Emit 'typing' event immediately
      setareYouTyping(true);
    } else { 
      if(!typingTimeoutRef.current) {
        // Set a new timeout to emit 'typing' after 5 seconds
        typingTimeoutRef.current = setTimeout(() => { 
            setareYouTyping(false); 
            typingTimeoutRef.current = null;
        }, 5000); // 5 seconds
      }
    }
  }; 

  const handleShowImage = (index) => {
    const updatedMessages = messages.map((msg, msgIndex) => {
      if (msgIndex === index) {
        // Toggle the suspicious flag
        return { ...msg, suspicious: msg.suspicious === 1 ? 0 : 1 };
      }
      return msg;
    });
  
    setMessages(updatedMessages);
  };

  useEffect(() => {
    // Scroll to the bottom when messages update or when isTyping changes
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    scrollToBottom();
  }, [messages, isTyping]);

  return (
    <div> 
        <div>
          <div className="chat-box">
            {loading ? (
              <div className="loading-container">
                <Oval type="Oval" color="#00BFFF" height={30} width={30} /> 
                <p>Waiting for a random user...</p>
              </div>
            ) : (
              <div>
              {randomUser? (
                <div>
                    {randomUser.image ? (
                      <img className={`image ${randomUser.review === 1 ? 'blur' : ''}`} src={`${BASE_URL}/up/${randomUser.review === 1 ? 'blur/' : ''}${randomUser.image}`} alt="profile" width="50" height="50" /> 
                    ) : (
                      <FaUserCircle className="image" />
                    )} 
                    <h3>
                      Chat with {randomUser.name}, {randomUser.age}
                    </h3>
                </div>
                ) : (
                  <div>
                    <h3>Start Chat with new random user</h3>
                  </div>
                )}
              </div>

            )}
            
            <div className="message-container">
                {messages.map((msg, index) => (
                  <div
                  className={`${msg.name === user.name ? 'user-message' : 'other-message'}`}
                  key={index}
                >
                  {msg.type === 1 ? ( 
                     <> 
                     <div className={msg.suspicious === 1 ? 'blur-image-container' : ''}>
                     {msg.suspicious === 1 && ( 
                         <div className="alert-message">May contain sensitive content. Click to view.</div>  
                     )}
                     <img src={msg.text} onClick={() => handleShowImage(index)} alt="Uploaded content" width="200" className={msg.suspicious === 1 ? 'blur-image' : ''} />
                     {msg.suspicious === 1 && ( 
                         <button className="report-button"  onClick={handleReportPopup}>Report?</button> 
                     )}
                     </div>
                   </>
                  ) : msg.users_id !== user.id && msg.type === 2 && !hiddenInputs.includes(msg.id) ? (
                    <> 
                      <div>Requested image:</div>
                      <input type="file" accept="image/*" onChange={handleImageChange(msg.id)}/> 
                    </>
                  ) : msg.type === 3 ? (
                    <>
                      {msg.text}
                      <button className="action-button" onClick={handleNewChat}>
                        New Chat?
                      </button>
                    </>
                  ) : msg.type === 4 ? (
                    <> 
                      {msg.text}
                    </>
                  ) :  (
                    <> 
                      {msg.text}
                    </>
                  )}
                </div>
              ))}
              
              {isTyping && (  
                  <div className="other-message"> 
                    <div className="typing-indicator">
                      <div className="bubble"></div>
                      <div className="bubble"></div>
                      <div className="bubble"></div>
                    </div>
                  </div>  
              )}


            {showImageInput && (
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            )}
      
            {alertMessage && (
              <div className="alert-message">
                {alertMessage}
              </div>
            )}

              <div ref={messagesEndRef} />
            </div>
            <input 
              className='message'
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleSendClick(); // Call handleSendClick when the user presses Enter
                } else {
                  handleTyping(); // Call handleTyping when the user starts typing
                }
              }}
              onPaste={handlePaste}  
              disabled={!randomUser}
            />

         <button className="send-button" onClick={handleSendClick} disabled={!randomUser}>
          Send
        </button>

        <div className='messages-nav'>
          <button className="back-button" onClick={handleDisconnect}>
            {confirmDisconnect ? 'Really?' : 'New Chat'}
          </button>
          <button className="report-button" onClick={handleReportPopup} disabled={!randomUser}>
            Report User
          </button> 
          <button className="report-button" onClick={handleRequestImage} disabled={!randomUser}>
            Request image
          </button> 
        </div>


          {showReportPopup && (
            <div className="popup-container">
              <div className="popup-content" ref={popupRef}>
                <button className="back-button" onClick={() => setShowReportPopup(false)}>
                  Back
                </button>
                <ReportPopup ref={popupRef} onClose={() => setShowReportPopup(false)} users_id={randomUser.id} reported_by={user.id} />
              </div>
            </div>
          )}
          </div>
        </div> 
    </div>
  );
};

export default RandomChat;
