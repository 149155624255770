import React from 'react';

function BeforeStart({handleShowOnline}) {
  return ( 
      <div>
        <span className="nav-title">Before you start:</span>
        <div className="welcome">
          <ul className="styled-list">
            <li>You must be at least 18 years old to use this chat site.</li>
            <li>Always be respectful and courteous towards other users.</li>
            <li>Do not share any personal information or contact details with anyone you meet here.</li>
            <li>Be cautious of users who share their contact information to lure you to other platforms.</li>
            <li>Never meet strangers in person you met here.</li>
            <li>You can report any illegal activity on this chat platform if there is any.</li>
            <li>Enjoy your time!</li>
          </ul>
        </div>
        <div style={{ textAlign: 'center' }}>
          <button onClick={handleShowOnline}>Show Online Users</button>
        </div>
      </div>
  );
}

export default BeforeStart;
