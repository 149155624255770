// src/components/Article.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../contexts/Constants'; 
import Footer from './Footer';
import HeaderNav from './HeaderNav';

function Article() {
  const { slug } = useParams();
  const [article, setArticle] = useState(null); 
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/articles/current/${slug}`);
        if (!response.ok) {
          setError('Failed to fetch article.'); 
          return;
        }

        const data = await response.json();
        setArticle(data); 
      } catch (error) {
        console.error('Failed to fetch article:', error);
        setError('Failed to fetch article.'); 
      }
    };

    fetchArticle();
  }, [slug]);
 

  if (error) {
    return <div>{error}</div>;
  }

  if (!article) {
    return <div>Article not found</div>;
  }

  return (
    <div className="welcome-container">
      <HeaderNav/>
      <h2>{article.title}</h2>
      <article>
        <div dangerouslySetInnerHTML={{ __html: article.text }} />
      </article>
      <Footer/>
    </div>
  );
}

export default Article;
