import React, { useState, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa'; // Import the default profile icon from React Icons
import CountryFlag from 'react-country-flag';
import { BASE_URL, API_BASE_URL } from '../contexts/Constants.js';
import { IoMailUnread } from "react-icons/io5"; // Importing an example mail icon

const Conversations = ({ user, conversations, onSelectConversation, socket, handleDeleteCoversatin }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionChecked, setSubscriptionChecked] = useState(false);
  const [deleteConfirmations, setDeleteConfirmations] = useState({});

  const handleSubscriptionToggle = async () => {
    try {
      const swRegistration = await navigator.serviceWorker.ready;

      const subscription = await swRegistration.pushManager.getSubscription();
      if (subscription) {
        // Unsubscribe
        await subscription.unsubscribe();
        await fetch(`${API_BASE_URL}/subscribe/remove`, {
          method: 'POST',
          body: JSON.stringify({ endpoint: subscription.endpoint, userId: user.id }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setIsSubscribed(false);
        console.log('User is unsubscribed');
      } else {
        // Subscribe
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          console.log('Permission not granted for Notification');
          return;
        }
        console.log('Notification permission granted');

        const newSubscription = await swRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array('BCCbl9VobTpJ24smDTRLl8pjS2_JQKNjHNaBdGc2McoqvCEQ1Po0Zl9xyaAKXJY9Vlj18vxO0_XenFU02KKZnHE'), // Replace with your VAPID key
        });
        console.log('Push Subscription:', newSubscription);

        const response = await fetch(`${API_BASE_URL}/subscribe`, {
          method: 'POST',
          body: JSON.stringify({ subscription: newSubscription, userId: user.id }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          console.log('Subscription saved on the server');
          setIsSubscribed(true);
        } else {
          console.error('Failed to save subscription on the server');
        }
      }
    } catch (error) {
      console.error('Failed to toggle subscription', error);
    }
  };

  // Helper function to convert VAPID key
  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  useEffect(() => {
    // Register the service worker when the component mounts
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(function (swReg) {
          console.log('Service Worker is registered', swReg);
        })
        .catch(function (error) {
          console.error('Service Worker Error', error);
        });

      // Check current subscription status
      navigator.serviceWorker.ready.then(async (swRegistration) => {
        const subscription = await swRegistration.pushManager.getSubscription();
        if (subscription) {
          setIsSubscribed(true);
        }
        setSubscriptionChecked(true);
      });
    } else {
      console.warn('Push messaging is not supported');
      setSubscriptionChecked(true);
    }
  }, []);

  const handleDeleteClick = (e, userId) => {
    e.stopPropagation(); // Prevent the click event from propagating to the parent div
  
    if (deleteConfirmations[userId]) {
      // Emit the delete conversation event
      socket.emit('removeConversation', { to_id: userId, user: user });
      handleDeleteCoversatin(userId);
      // Reset the delete confirmation state for this user
      setDeleteConfirmations((prevState) => ({ ...prevState, [userId]: false }));
    } else {
      // Show the delete confirmation
      setDeleteConfirmations((prevState) => ({ ...prevState, [userId]: true }));
    }
  };

  return (
    <>
      <div className="conversations-list">
        {conversations.length === 0 ? (
          <p>No conversations yet.</p>
        ) : (
          <div className="users-list">
            {conversations.map((u, i) => (
              <div className="list" key={i}>
                <div onClick={() => onSelectConversation(u)}>
                  {u.online === 1 ? (
                    <div className="online"></div>
                  ) : (
                    <div className="offline"></div>
                  )}

                  {u.image ? (
                    <img
                      className={`image ${u.review === 1 ? 'blur' : ''}`}
                      src={`${BASE_URL}/up/${u.review === 1 ? 'blur/' : ''}${u.image}`}
                      alt="profile"
                      width="50"
                      height="50"
                    />
                  ) : (
                    <FaUserCircle className="image" />
                  )}

                  <h3 className={'gender_' + u.gender}>
                    {u.name}, {u.age}

                    <CountryFlag className="flag" countryCode={u.country} svg/>

                    {u.seen === 0 ? (
                      <IoMailUnread className="new-message" />
                    ) : null}

                  </h3>
                   
                  
                 <button
                    className="delete-button"
                    onClick={(e) => handleDeleteClick(e, u.id)}
                  >
                    {deleteConfirmations[u.id] ? 'Confirm' : 'Remove'}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {subscriptionChecked && !isSubscribed && (
        <div style={{ textAlign: 'center' }}> 
          <button onClick={handleSubscriptionToggle}>
            Enable Notifications
          </button> 
        </div>
      )} 
    </>
  );
};

export default Conversations;
