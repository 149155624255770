import React, { useState, useEffect } from 'react';
import { FaAndroid, FaApple } from 'react-icons/fa';
import { logEvent } from '../contexts/Analytics.js'; // Import the logEvent function

const InstallButton = () => {
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const isIosDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIos(isIosDevice);
    
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || 
                         (window.navigator.standalone && !window.matchMedia('(display-mode: browser)').matches);
    setIsInStandaloneMode(isStandalone);

    if (isStandalone) {
      logEvent('PWA', 'Standalone Mode', 'App is running in standalone mode');
    }

    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  const handleInstallClick = () => {
    if (isIos && !isInStandaloneMode) {
      alert(
        'To install this app, open the Share menu in Safari and select "Add to Home Screen".'
      );
    } else if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    } else {
      alert('Install prompt is not available.');
    }
  };

  if (isInStandaloneMode || !deferredPrompt) {
    return null;
  }

  return (
    <button className="use-as-app" onClick={handleInstallClick}>
      {isIos ? <FaApple size={24} /> : <FaAndroid size={24} />}
      <span style={{ marginLeft: 8 }}>Use as an app</span>
    </button> 
  );
};

export default InstallButton;
