import React from 'react'; 
import Footer from './Footer';
import HeaderNav from './HeaderNav';

function TermsAndConditions() {
  return ( 
    <>
    
    <div className="welcome-container">
    <HeaderNav/>
      <h2>Terms and Conditions</h2>
      <p>
        Welcome to our chat application. By using this application, you agree to
        comply with and be bound by the following terms and conditions of use.
        Please read these terms carefully before using the application.
      </p>
      <h3>1. Age Requirement</h3>
      <p>
        You must be at least 18 years old to use this Application. By registering
        and using the Application, you confirm that you are 18 years of age or
        older and that any registration information you submit is accurate.
      </p>
      <h3>2. Purpose</h3>
      <p>
        This Application is intended solely for entertainment purposes. Users agree
        to use the Application responsibly and not engage in activities that may be
        considered illegal, offensive, harmful, or inappropriate.
      </p>
      <h3>3. User Interactions</h3>
      <p>
        You are solely responsible for your interactions and communications with other
        users. While using the Application, you understand and agree that PogiChat is
        not responsible for the behavior, actions, or content of any user. Any disputes
        between users are to be resolved between the parties directly, without involving us.
      </p>
      <h3>4. Monitoring and Moderation</h3>
      <p>
        We reserve the right, but not the obligation, to monitor and moderate content
        and interactions within the Application to ensure a positive environment.
        Inappropriate behavior, including but not limited to harassment, hate speech,
        explicit content, or promoting illegal activities, may result in warnings,
        suspension, or permanent banishment from the Application. However, we cannot
        guarantee that all inappropriate behavior will be identified or addressed.
      </p>
      <h3>5. Privacy</h3>
      <p>
        Your privacy is paramount to us. By using the Application, you consent to our
        data practices as outlined in our privacy policy. This policy explains the
        information we collect, its usage, and protection. Ensure that you review the
        privacy policy periodically for any updates or changes.
      </p>
      <h3>6. Modifications to the Terms</h3>
      <p>
        We reserve the right to modify or update these terms and conditions at any time,
        for any reason, without prior notice. It is your responsibility to check these
        terms periodically for changes. Your continued use of the Application following
        the posting of changes or modifications will mean you accept those changes or modifications.
      </p>
      <h3>7. Disclaimers</h3>
      <p>
        The Application is provided "AS IS" without warranties of any kind, whether express
        or implied, including accuracy, reliability, and any implied warranties of
        merchantability, fitness for a particular purpose, or non-infringement. We do not
        guarantee that the Application will always be safe, secure, or error-free or that
        the Application will always function without disruptions, delays, or imperfections.
      </p>
      <h3>8. Limitation of Liability</h3>
      <p>
        To the maximum extent permitted by applicable law, PogiChat shall not be liable
        for any indirect, incidental, special, consequential, or punitive damages or any
        loss of profits or revenues, whether incurred directly or indirectly, or any loss
        of data, use, goodwill, or other intangible losses resulting from (a) your access
        to or use of or inability to access or use the Application; (b) the behavior or
        content of other users or third parties on or through the Application.
      </p>
      <h3>Conclusion</h3>
      <p>
        If you do not agree to these terms and conditions in their entirety, your sole remedy
        is not to use the Application. If you have questions or concerns about these terms,
        please contact us using contact form.
      </p>
    <Footer/>
    </div> 

    </>
  );
  
}

export default TermsAndConditions;
