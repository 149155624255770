// src/components/Articles.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../contexts/Constants'; 
import Footer from './Footer';
import HeaderNav from './HeaderNav';

function Articles() {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/articles/all`);
        if (!response.ok) {
          setError('Failed to fetch articles.');
          return;
        }

        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error('Failed to fetch articles:', error);
        setError('Failed to fetch articles.');
      }
    };

    fetchArticles();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="welcome-container">
         <HeaderNav/>
      <h2>Articles About Chatting</h2>
      <ul className="articles-list">
        {articles.map(article => (
          <li key={article.link}>
            <h3>{article.title}</h3>
            {article.description}
            <Link to={`/articles/${article.link}`}>Read more</Link>
          </li>
        ))}
      </ul>

      <Footer/>
    </div>
  );
}

export default Articles;
