import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ContactPage from './ContactPage';

function Footer({ user, onLogout }) {
  const [showContactPage, setShowContactPage] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(null);
  const [showLogoutConfirmDialog, setShowLogoutConfirmDialog] = useState(false);

  const handleLogoutClick = () => {
    setShowLogoutConfirmDialog(true);
  };

  const handleConfirmLogout = () => {
    setShowLogoutConfirmDialog(false);
    onLogout();
  };

  const handleCloseDialog = () => {
    setShowLogoutConfirmDialog(false);
  };

  const openPopup = (page) => {
    switch (page) {
      case 'contact':
        setShowContactPage(true);
        break;
      default:
        break;
    }
    document.body.style.overflow = 'hidden';
  };

  useEffect(() => {
    const savedConsent = localStorage.getItem('cookieConsent');
    setCookieConsent(savedConsent);
  }, []);

  const handleCookieAcceptance = () => {
    setCookieConsent('accepted');
    localStorage.setItem('cookieConsent', 'accepted');
  };

  const closePopup = () => {
    setShowContactPage(false);
    document.body.style.overflow = 'auto';
  };

  const popupRef = useRef(null);

  const LogoutConfirmDialog = ({ onClose, onConfirm }) => {
    return (
      <div className="popup-container">
        <div className="popup-content">
          <button className="back-button" onClick={onClose}>
            Back
          </button>
          <p>Are you sure you want to remove your profile? You will not be able to log in to this account again, and all data will be permanently deleted within 24 hours.</p>
          <button onClick={onConfirm}>Yes, Remove Profile</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="footer-meniu">
      {showLogoutConfirmDialog && (
        <LogoutConfirmDialog
          onClose={handleCloseDialog}
          onConfirm={handleConfirmLogout}
        />
      )}

      <div className="button-container">

        <Link to="/terms-and-conditions" className="footer-buttoon">
          Terms and Conditions
        </Link>
        <Link to="/cookie-policy" className="footer-buttoon">
          Cookie Policy
        </Link>
        <Link to="/privacy-policy" className="footer-buttoon">
          Privacy Policy
        </Link>
        <Link to="/articles" className="footer-buttoon">
          Articles
        </Link> 
        <button className="footer-buttoon" onClick={() => openPopup('contact')}>
          Contact
        </button>
        {user && (
          <button className="footer-buttoon" onClick={handleLogoutClick}>
            Remove Profile
          </button>
        )}
      </div>

      {showContactPage && (
        <div className="popup-container">
          <div className="popup-content" ref={popupRef}>
            <button className="back-button" onClick={closePopup}>
              Back
            </button>
            <ContactPage />
          </div>
        </div>
      )}

      {!cookieConsent && (
        <div className="cookie-consent-box">
          <p>
            We use <Link to="/cookie-policy">cookies</Link> to enhance your experience. By continuing to visit
            this site you agree to our use of cookies.
          </p>
          <button className='action-button' onClick={handleCookieAcceptance}>Accept</button>
        </div>
      )}
    </div>
  );
}

export default Footer;
