import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { API_BASE_URL } from '../contexts/Constants.js';

function ContactPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseError, setResponseError] = useState(false); // New state for error
  const [captchaValue, setCaptchaValue] = useState('');


  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleRecaptchaChange = (value) => {
    setIsVerified(!!value);
    setCaptchaValue(value);
};

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isVerified) {
      try {
        const response = await fetch(API_BASE_URL+'/contacts/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, message, captcha: captchaValue })
        });

        const data = await response.json();

        if (response.ok) { 
          setResponseMessage(data.success); // Assuming the server sends a 'success' key for successful operations
          setResponseError(false);
          setEmail('');
          setMessage('');
          setIsVerified(false);
        } else {
          setResponseMessage(data.error); // Assuming the server sends an 'error' key for errors
          setResponseError(true);
        }
      } catch (error) {
        console.error('Error:', error);
        setResponseMessage('An unexpected error occurred.');
        setResponseError(true);
      }
    } else {
      alert('Please complete the reCAPTCHA verification.');
    }
  };

  return (
    <div className="contact-page">
    
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
            <label>Email:</label>
            <input type="email" value={email} onChange={handleChangeEmail} required />
          </div>
          <div className="form-group">
            <label>Message:</label>
            <textarea value={message} onChange={handleChangeMessage} required />
          </div>
          <div className="form-group">
            <ReCAPTCHA sitekey="6LeFN9soAAAAABmxBrSJSZtOrH0-384fhr_liA-G" onChange={handleRecaptchaChange} />
          </div>

          {responseMessage && (
            <div className={`response-message ${responseError ? 'error' : 'success'}`}>
              {responseMessage}
            </div>
          )}


          <div className="form-group">
            <button type="submit">Submit</button>
          </div>
      </form>
    </div>
  );
}
  
export default ContactPage;
