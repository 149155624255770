import React, { useEffect, useState, useRef } from 'react';
import ReportPopup from './ReportPopup'; // Adjust the path as needed
import { FaUserCircle } from 'react-icons/fa'; // Import the default profile icon from React Icons 
import CountryFlag from 'react-country-flag';
import { BASE_URL } from '../contexts/Constants.js';
import CountryData from '../contexts/CountryData.js'; // adjust the path as needed 
import GenderIcon from './GenderIcon';
import { register } from '../serviceWorkerRegistration'; // Import the service worker registration

const Messages = ({ user, selectedUser, socket, handleDeleteCoversatin, newMessage }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [hiddenInputs, setHiddenInputs] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false); // New state
  const messagesEndRef = useRef(null);
  const alertMessageRef = useRef(null);
  const [seenStatus, setSeenStatus] = useState(0);
  const popupRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [areYouTyping, setareYouTyping] = useState(false);
  const typingTimeoutRef = useRef(null);
  const is_typing = useRef(null);
  const [userBlocked, setUserBlocked] = useState(false);
  const [userMessageCount, setUserMessageCount] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageLimit, setMessageLimit] = useState({
    count: 0,
    hasResponded: false,
  });

  const hereForOptions = {
    "1": "Flirt",
    "2": "Clean Chat",
    "3": "Language Exchange",
  };

  const getTimeDifference = (timestamp) => {
    const now = new Date();
    const lastOnline = new Date(timestamp);
    const diffMs = now - lastOnline;
    const diffMins = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffMins < 1) {
      return "online just now";
    } else if (diffMins < 60) {
      return `online ${diffMins} minutes ago`;
    } else if (diffHours < 24) {
      return `online ${diffHours} hours ago`;
    } else {
      return `online ${diffDays} days ago`;
    }
  };

  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste action
    setAlertMessage('Pasting text is disabled.'); // Optional: Show a message indicating that paste is disabled
    // You can remove the above line if you don't want to show any alert message
    setTimeout(() => {
      setAlertMessage('');
    }, 5000);
  };

  const getCountryName = (countryCode) => {
    return CountryData[countryCode] || '';
  };

  const handleBlockClick = () => {
    const blockedUsers = JSON.parse(localStorage.getItem('blockedUsers') || '[]');
    if (!blockedUsers.includes(selectedUser.id)) {
      blockedUsers.push(selectedUser.id);
      localStorage.setItem('blockedUsers', JSON.stringify(blockedUsers));
      setUserBlocked(true);
      setMessages([]); // Clear the messages
      console.log('User blocked successfully!');
    } else {
      const updatedBlockedUsers = blockedUsers.filter((id) => id !== selectedUser.id);
      localStorage.setItem('blockedUsers', JSON.stringify(updatedBlockedUsers));
      socket.emit('getMessages', { id: selectedUser.id });
      setUserBlocked(false);
      console.log('User unblocked successfully.');
    }
  };

  
  const handleTypingStranger = (id) => {
    if (id === selectedUser.id) {
      setIsTyping(true);
      clearTimeout(is_typing.current);
      is_typing.current = setTimeout(function () {
        setIsTyping(false);
      }, 6000);
    }
  };

  const isPhoneNumber = (text) => {
    var pattern = /(\d[\D]{0,2}){6,}\d/g;
    var matches = text.match(pattern);
    if (matches) {
      // If any match is found, consider it as having a phone number pattern
      return true;
    }
    // No matches found resembling a phone number pattern
    return false;
  };

  const handleMessage = (msg) => {
    if (msg.users_id === selectedUser.id) {
      const blockedUsers = JSON.parse(localStorage.getItem('blockedUsers') || '[]');
      if (blockedUsers.includes(msg.users_id)) {
        // If the user is blocked, don't process the message
        return;
      }
      setMessages((prevMessages) => [...prevMessages, msg]);
      setIsTyping(false);
      setUserMessageCount((userMessageCount) => userMessageCount + 1);

      setSeenStatus(0);
      setMessageLimit({
        count: 0, // Reset the count or keep it as is, based on your logic
        hasResponded: true, // Mark that the user has responded
      });
    }
  };

  useEffect(() => {
    const blockedUsers = JSON.parse(localStorage.getItem('blockedUsers') || '[]');
    setUserBlocked(blockedUsers.includes(selectedUser.id));
  }, [selectedUser.id]);

  useEffect(() => {
    // Register the event listeners
    socket.on('typing', handleTypingStranger);

    // Clean up the event listeners
    return () => {
      socket.off('typing', handleTypingStranger);
    };
  }, [socket, selectedUser]);

  useEffect(() => {
    if (newMessage) {
      handleMessage(newMessage);
    }
  }, [newMessage]);

  const handleReportPopup = () => {
    setShowReportPopup(!showReportPopup);
  };

  const handleDeleteClick = () => {
    if (showDeleteConfirmation) {
      // Emit the delete conversation event
      socket.emit('removeConversation', { to_id: selectedUser.id, user: user });
      // Clear the messages and close the delete confirmation
      setMessages([]);
      setShowDeleteConfirmation(false);
      handleDeleteCoversatin(selectedUser.id);
    } else {
      // Show the delete confirmation
      setShowDeleteConfirmation(true);
    }
  };

  useEffect(() => {
    setTimeout(function () {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }, [messages, isTyping]);

  useEffect(() => {
    if (alertMessage && alertMessageRef.current) {
      alertMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [alertMessage]);

  useEffect(() => {
    // Register the service worker when the Messages component mounts
    register();
  }, []);

  useEffect(() => {
    const blockedUsers = JSON.parse(localStorage.getItem('blockedUsers') || '[]');
    if (!blockedUsers.includes(selectedUser.id)) {
      socket.emit('getMessages', { id: selectedUser.id });
    } else {
      setMessages([]); // Clear messages if the user is blocked
    }

    socket.on('getMessages', (data) => {
      let messages = [];
      let seen = 0;

      if (Array.isArray(data)) {
        // If data is an array, it's the old format
        messages = data;
      } else if (data.messages) {
        // If data contains messages, it's the new format
        messages = data.messages;
        seen = data.seen || 0;
      }

      setUserMessageCount(messages.length);

      // Update seen status only if the last message is not from the user
      if (messages.length > 0 && messages[0].users_id === user.id) {
        setSeenStatus(seen);
      } else {
        setSeenStatus(0);
      }

      const newMessages = messages
        .map((msg) => {
          let userName = selectedUser.id === msg.users_id ? selectedUser.name : user.name;
          return {
            ...msg, // Copy all properties of the msg object
            name: userName, // Override only the name property
          };
        })
        .reverse();

      setMessages(newMessages);

      setIsTyping(false);
    });

    return () => {
      socket.off('getMessages');
    };
  }, [selectedUser]);

  const handleSendClick = () => {
    if (messageLimit.count >= 3 && !messageLimit.hasResponded) {
      setAlertMessage("You cannot send more than 3 messages until the other user responds.");
      setTimeout(() => {
        setAlertMessage('');
      }, 5000);
      return;
    }

    if (/\bhttps?:\/\/\S+\b/ig.test(message)) {
      setAlertMessage('Links are not allowed in messages.');
      setMessage('');
      setTimeout(() => {
        setAlertMessage('');
      }, 5000);
      return;
    }

    // Check if message contains phone numbers
    if (isPhoneNumber(message)) {
      setAlertMessage('Phone numbers are not allowed in messages.');
      setMessage('');
      setTimeout(() => {
        setAlertMessage('');
      }, 5000);
      return;
    }

    if (message.trim().length > 0) {
      setMessageLimit((prevLimit) => ({
        ...prevLimit,
        count: prevLimit.count + 1,
      }));

      socket.emit('message', {
        id: selectedUser.id,
        socket: selectedUser.socket,
        name: user.name,
        message: message,
        type: 0,
      });
      setMessages((prevMessages) => [
        ...prevMessages,
        { name: user.name, users_id: user.id, message: message, type: 0 },
      ]);
      setMessage('');
      setSeenStatus(0);
    }
  };

  const handleTyping = () => {
    if (!areYouTyping) {
      socket.emit('typing', { to_id: selectedUser.id, users_id: user.id }); // Emit 'typing' event immediately
      setareYouTyping(true);
    } else {
      if (!typingTimeoutRef.current) {
        // Set a new timeout to emit 'typing' after 5 seconds
        typingTimeoutRef.current = setTimeout(() => {
          setareYouTyping(false);
          typingTimeoutRef.current = null;
        }, 5000); // 5 seconds
      }
    }
  };

  const handleShowImage = (index) => {
    const updatedMessages = messages.map((msg, msgIndex) => {
      if (msgIndex === index) {
        // Toggle the suspicious flag
        return { ...msg, suspicious: msg.suspicious === 1 ? 0 : 1 };
      }
      return msg;
    });

    setMessages(updatedMessages);
  };

  const handleImageChange = (imageReqId) => (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Compress the image to 80x80 using an HTML canvas element
        const image = new Image();

        image.onload = () => {
          const maxSize = 200;
          let width = image.width;
          let height = image.height;

          // Calculate the aspect ratio
          const aspectRatio = width / height;

          // Determine the new dimensions while maintaining the aspect ratio
          if (width > height) {
            width = maxSize;
            height = maxSize / aspectRatio;
          } else {
            height = maxSize;
            width = maxSize * aspectRatio;
          }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');

          // Draw the loaded image onto the canvas with the new dimensions
          ctx.drawImage(image, 0, 0, width, height);

          // Convert the image to base64 code
          const base64Image = canvas.toDataURL('image/jpeg'); // Adjust quality as needed

          // You can add some logic to send this base64Image to the server if needed
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              name: user.name,
              message: base64Image,
              type: 1,
              users_id: user.id,
            },
          ]);

          socket.emit('message', {
            id: selectedUser.id,
            socket: selectedUser.socket,
            name: user.name,
            message: base64Image,
            type: 1,
            input: imageReqId,
          });

          setHiddenInputs((prev) => [...prev, imageReqId]);
        };

        image.src = reader.result;
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRequestImage = () => {
    if (userMessageCount < 2) {
      // Alert or display a message that more messages are needed
      setAlertMessage("You need to chat a little bit before requesting an image.");
      // Clear the message after 5 seconds
      setTimeout(() => {
        setAlertMessage('');
      }, 5000);
      return;
    }
    socket.emit('message', { id: selectedUser.id, socket: selectedUser.socket, name: user.name, message: "Requested image...", type: 2 });

    setMessages((prevMessages) => [
      ...prevMessages,
      { name: user.name, message: "Requested image...", type: 0 },
    ]);
  };

  return (
    <div className="chat-box">
      {selectedUser.online === 1 ? (
        <div className="online"></div>
      ) : (
        <div className="offline"></div>
      )}
      {selectedUser.image ? (
        <img
          src={`${BASE_URL}/up/${selectedUser.review === 1 ? 'blur/' : ''}${selectedUser.image}`}
          alt="profile"
          className={`image ${selectedUser.review === 1 ? 'blur' : ''}`}
          width="50"
          height="50"
        />
      ) : (
        <FaUserCircle className="image" />
      )}

      <h3 className={'gender_' + selectedUser.gender}>
        <GenderIcon gender={selectedUser.gender} /> {selectedUser.name}, {selectedUser.age}
      </h3>

      <div className="last-online">
        {selectedUser.onlineDate && (
          <div>{getTimeDifference(selectedUser.onlineDate)}</div>
        )}
      </div>

      {selectedUser.country && (
        <div>
          <CountryFlag
            className="flag"
            countryCode={selectedUser.country}
            svg
            style={{ width: '20px', height: '20px', marginRight: '5px' }}
          />
          {getCountryName(selectedUser.country)}
        </div>
      )}

      <div>
        {selectedUser.here > 0 && (
          <div className="language-tag">
            Here For {hereForOptions[selectedUser.here] || 'N/A'}
          </div>
        )}

        {selectedUser.languages && selectedUser.languages.length > 0 && (
          <div className="language-tag">
            Languages:
            {selectedUser.languages}
          </div>
        )}
      </div>

      <div className="message-container">
        {messages.map((msg, index) => (
          <div
            className={`${msg.users_id !== user.id ? 'other-message' : 'user-message'}`}
            key={index}
          >
            {msg.type === 1 ? (
              // For image messages
              <div className={msg.suspicious === 1 ? 'blur-image-container' : ''}>
                {msg.suspicious === 1 && (
                  <div className="alert-message">May contain sensitive content. Click to view.</div>
                )}
                <img
                  src={msg.message}
                  onClick={() => handleShowImage(index)}
                  alt="Uploaded content"
                  width="200"
                  className={msg.suspicious === 1 ? 'blur-image' : ''}
                />
                {msg.suspicious === 1 && (
                  <button className="report-button" onClick={handleReportPopup}>
                    Report?
                  </button>
                )}
              </div>
            ) : msg.users_id !== user.id && msg.type === 2 && !hiddenInputs.includes(msg.id) ? (
              // For requested image
              <div>
                <div>Requested image:</div>
                <input type="file" accept="image/*" onChange={handleImageChange(msg.id)} />
              </div>
            ) : (
              // For text messages
              msg.message
            )}
          </div>
        ))}

        {isTyping && (
          <div className="other-message">
            <div className="typing-indicator">
              <div className="bubble"></div>
              <div className="bubble"></div>
              <div className="bubble"></div>
            </div>
          </div>
        )}

        {alertMessage && (
          <div ref={alertMessageRef} className="alert-message">
            {alertMessage}
          </div>
        )}

        {seenStatus === 1 && (
          // Conditionally render the "seen" message
          <div className="seen-message">
            Seen
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>

      <input
        className="message"
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleSendClick(); // Call handleSendClick when the user presses Enter
          } else {
            handleTyping(); // Call handleTyping when the user starts typing
          }
        }}
        onPaste={handlePaste}
        disabled={userBlocked}
        placeholder={userBlocked ? "This user is blocked. " : "Type your message..."}
      />
      <button className="send-button" onClick={handleSendClick}>
        Send
      </button>

      <div className="messages-nav">
        <button className="report-button" onClick={handleRequestImage}>
          Req img
        </button>
        <button className="report-button" onClick={handleReportPopup}>
          Report
        </button>
        <button className="block-button" onClick={handleBlockClick}>
          {userBlocked ? 'Unblock' : 'Block '}
        </button>
        <button className="delete-button" onClick={handleDeleteClick}>
          {showDeleteConfirmation ? 'Really?' : 'Remove'}
        </button>
      </div>

      {showReportPopup && (
        <div className="popup-container">
          <div className="popup-content" ref={popupRef}>
            <button className="back-button" onClick={() => setShowReportPopup(false)}>
              Back
            </button>
            <ReportPopup ref={popupRef} onClose={() => setShowReportPopup(false)} users_id={selectedUser.id} reported_by={user.id} />
          </div>
        </div>
      )}

    </div>
  );
};

export default Messages;
