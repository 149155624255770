import React, { useState, forwardRef} from 'react'; 
import { API_BASE_URL } from '../contexts/Constants.js';

const ReportPopup = forwardRef(({ onClose, users_id, reported_by }, ref) => {
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [isReportSent, setIsReportSent] = useState(false);

  const handleReport = async () => {
    if (!reason) {
      // Handle if no reason is selected
      return;
    }
  
    // Construct the report data to send
    const reportData = {
        reported_by: reported_by,
        users_id: users_id, // Pass the userId here
        reason: reason === 'other' ? otherReason : reason,
        // Include any other relevant data here
    };

    try {
      // Send the report to the server
      const response = await fetch(API_BASE_URL+'/report/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reportData),
      });

      if (response.ok) {
        setIsReportSent(true);
        setReason('');
        setOtherReason('');
      } else {
        // Handle if the report request fails
      }
    } catch (error) {
      // Handle any errors during the request
    }
  };

  const handleClose = () => {
    setReason('');
    setOtherReason('');
    onClose();
  };

  return (
    <div id="report-popup" className="report-popup">
      {isReportSent ? (
        <div>
          <p>Report submitted successfully.</p>
          <p>We will review it within 24 hours and take any necessary actions.</p>
        </div>
      ) : (
        <div>
          <h3>Report User</h3>
          <p>Select a reason for reporting:</p>
          <select value={reason} onChange={(e) => setReason(e.target.value)}>
            <option value="">Select a reason</option>
            <option value="inappropriate_behavior">Inappropriate Behavior</option>
            <option value="spam">Spam</option>
            <option value="other">Other</option>
          </select>

          {reason === 'other' && (
            <div>
              <p>Please provide the reason:</p>
              <input
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                maxLength={250} 
              />
            </div>
          )}

          <button class="send-button" onClick={handleReport}>Submit</button>
          <button onClick={handleClose}>Close</button>
        </div>
      )}
    </div>
  );
});

export default ReportPopup;
