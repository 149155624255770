import React from 'react';

function ConnectionLost() {

  const handleReloadClick = () => {
    window.location.reload(); // Reload the page
  };

  return ( 
        <div className="popup-container">
        <div className="popup-content">
        <p>Oops! Connection to server lost.</p>
        <button className="back-button" onClick={handleReloadClick}>
            Reload Page
        </button>
        </div>
    </div> 
  );
}

export default ConnectionLost;
