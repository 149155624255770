//src/components/SignIn.js
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState, useEffect } from 'react'; 
import Footer from './Footer';
import { FaMale, FaFemale,FaComments, FaUserFriends, FaRandom, FaGlobe, FaRocketchat} from 'react-icons/fa'; // Import icons for gender
import { API_BASE_URL } from '../contexts/Constants.js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import {getSession, setSession } from '../services/auth'; // Import the auth functions

import './App.css'; // Import the SignIn component styles 
 

function SignIn({ onSignIn }) {

  // Check for existing user session on component mount
  useEffect(() => {
    const userSession = getSession();
    if (userSession) { 
      setGender(userSession.gender); 
    }
  }, []);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [gender, setGender] = useState('');  
  const [error, setError] = useState(null);
  const [fingerprint, setFingerprint] = useState(''); 
  const [currentStep, setCurrentStep] = useState(1); // 1: username, 2: gender, 3: age 
   
  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    // You can also directly validate the token with your backend here if needed
  };

  const clickStart = async () => {
    setCurrentStep(2);
  };
 
  // Function to handle user sign-in and session management
  const handleSignIn = async (data) => {
    try {
      const { sessionId, id, name, gender, age, here, languages} = data;
      setSession({ sessionId, id, name, gender, age, here, languages});
      onSignIn({ sessionId, id, name, gender, age, here, languages}); 

      if (data.sessionId && data.id) { 
        // Assuming this is where you confirm the user is signed in successfully
        handleGTagReportConversion();
      }
    } catch (error) {
      console.error('Failed to sign in:', error);
      setError('Failed to sign in. Please try again later.');
    }
  };
  
   // Function to call gtag_report_conversion
   const handleGTagReportConversion = () => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
          'send_to': 'AW-961757397/31gZCNniuo4ZENWBzcoD'
      });
    } else {
      console.error('gtag is not defined. Ensure you have included the Google Ads gtag.js script in your application.');
    }
  };

  // Function to generate the fingerprint
  const generateFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    setFingerprint(result.visitorId);
  };

  // Generate the fingerprint when the component mounts
  useEffect(() => {
    generateFingerprint(); 
  }, []);
 

  const onSubmit = async (e, isAgeConfirmed) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    if (!isAgeConfirmed) {
      // Reset the form if the user is under 18 
      setGender(''); 
      setCurrentStep(1);
      return; // Exit the function early
    } 

    if (!gender || ![1, 2].includes(Number(gender))) {
      setError("Please select a valid gender.");
      return;
    } 

    // Check if reCAPTCHA is solved
    if (!recaptchaToken) {
      setError('Please verify you are not a robot.');
      return;
    }

    try { 

      if (!gender) {
        // Perform client-side validation
        setError('Please fill in all fields');
        return;
      }

      // Send the user data to the server using the fetch API
      const response = await fetch(API_BASE_URL + '/signin/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({gender, fingerprint, recaptchaToken}),
      });

      if (!response.ok) { 
          // If the response is not OK (status code 200-299), handle the error
          setError('Failed to sign in. Please try again later.');
          return;
      }

      // Parse the response data as JSON
      const data = await response.json();

      // check if data.exists is egsist or not
      if(data.error) {
        setError(data.error);
      } else if (data.exists) {
        // Show suggestions 
        setError(null);
      } else {
        if (data.sessionId && data.id) { 
          handleSignIn(data); 
        }
      }
    } catch (error) {
      console.error('Failed to sign in:', error);
      setError('Failed to sign in. Please try again later.');; 
    }
  };

  return (
    <div className="signin-container">
      <form onSubmit={(e) => onSubmit(e, true)}>
        <header className="header">
          <img src="logo.png" className="logo" alt="Logo" />
        </header>

        <h1>Chat with strangers</h1>
        <div className="description">
          <p>Chat with strangers and experience a random chat alternative to connect with people, find friends, and explore diverse perspectives from all over the world!</p>
        </div>
        
        {error && <div className="error-message">{error}</div>}
        {currentStep === 1 && (
          <div className="sign-nav">
            <button className="start-chatting" type="button" onClick={clickStart}><FaRocketchat className="start-icon" />Start Chatting</button>
          </div>
        )}

        {currentStep === 2 && (
          <div className="input-container">
            <label>Gender:</label>
            <div className="gender-selection">
              <label className="gender-option">
                <input
                  type="radio"
                  name="gender"
                  value="2"
                  id="genderMale"
                  checked={gender === '2'}
                  onChange={(e) => setGender(e.target.value)}
                />
                <FaMale className="gender-icon" />
                <span className="gender-label">Male</span>
              </label>
              <label className="gender-option">
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  id="genderFemale"
                  checked={gender === '1'}
                  onChange={(e) => setGender(e.target.value)}
                />
                <FaFemale className="gender-icon" />
                <span className="gender-label">Female</span>
              </label>
            </div>

            <small>By starting the chat, you agree to our "terms and conditions" and "privacy policy".</small>

            <div className="sign-nav">
              <ReCAPTCHA
                sitekey="6LeFN9soAAAAABmxBrSJSZtOrH0-384fhr_liA-G"
                onChange={onRecaptchaChange}
              />
              <button className="submit-button" onClick={(e) => onSubmit(e, true)}>Yes, I'm 18 or older</button>
              <button className="submit-button no" onClick={(e) => onSubmit(e, false)}>No, I'm under 18</button>
            </div>
          </div>
        )}
      </form>

      <div className="pogichat-welcome description">
        <div className="welcome-header">
          <h2>Free Chat Rooms</h2>
        </div>
        <p>Looking for a casual chat, an unexpected conversation, or to experience different cultures? Our platform provides a range of chat rooms that match your interests perfectly.</p>
      </div>
      
      <div className="description">
        <section>
          <FaComments className="description-icon" />
          <h3>Discover Free Chat Rooms for Engaging Conversations</h3>
          <p>Join our lively chat rooms and connect with a global community. Discuss your favorite subjects, share insights, or make new friends with just a click. PogiChat's chat rooms are designed to welcome everyone.</p>
        </section>
        <section>
          <FaRandom className="description-icon" />
          <h3>Embark on a Random Chat Adventure</h3>
          <p>Experience the excitement of spontaneity with our Random Chat feature. Each click connects you randomly, offering the thrill of discovering who you'll meet next. Enjoy anonymous and dynamic conversations that expand your world view.</p>
        </section>
        <section>
          <FaUserFriends className="description-icon" />
          <h3>Chat with Strangers and Expand Your Horizons</h3>
          <p>Connect with strangers globally and widen your social circle. Share your stories, revel in laughter, and have deep conversations in an engaging environment.</p>
        </section>
        <section>
          <FaGlobe className="description-icon" />
          <h3>Experience Global Connections in Our International Chat</h3>
          <p>Our International Online Chat invites you to make worldwide connections. Overcome cultural boundaries and engage in meaningful exchanges from the comfort of your home, connecting with people from every corner of the globe.</p>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default SignIn;