import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import RandomChat from './RandomChat';
import Messages from './Messages';
import Header from './Header';
import Footer from './Footer';
import Conversations from './Conversations';
import ConnectionLost from './ConnectionLost';
import BeforeStart from './BeforeStart';
import { FaUserCircle, FaMale, FaFemale, FaUsers, FaRandom } from 'react-icons/fa';
import CountryFlag from 'react-country-flag';
import { BASE_URL, API_BASE_URL, VERSION } from '../contexts/Constants.js';
import { IoMailUnread } from "react-icons/io5";
import { useNavigate, useLocation } from 'react-router-dom';
import InstallButton from './InstallButton'; // Import the InstallButton component


function Welcome({ user, onLogOut }) {
  const navigate = useNavigate();
  const [showOnline, setShowOnline] = useState(false);
  const [showRandom, setShowRandom] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [messageStatus, setMessageStatus] = useState(0);
  const [newMessage, setNewMessage] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [onlineUserCount, setOnlineUserCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const [genderFilter, setGenderFilter] = useState(null);
  const location = useLocation();
  const [dataUpdateTime, setDataUpdateTime] = useState(null);
  const [socket, setSocket] = useState(null);
  const [isConnectionLost, setIsConnectionLost] = useState(false);
  
  useEffect(() => { 
    
    // Initialize socket connection
    const newSocket = io(API_BASE_URL, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 3,
      reconnectionDelay: 3000
    });
    setSocket(newSocket);

    // Event listeners and socket logic
    newSocket.on('connect', () => {
      console.log('Socket connected'); 
      setIsConnectionLost(false); // Reset connection lost flag when connected
    });

    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      if (isConnectionLost) { 
        setIsConnectionLost(true); // Reset connection lost flag when connected
      }
    });

    // Add a socket event handler for 'disconnect'
    newSocket.on('disconnect', () => {
      console.log('Socket disconnected');
      setIsConnectionLost(true); 
    });

    // Cleanup function
    return () => {
      if (newSocket) {
        newSocket.close();
      }
    };
  }, []);

  useEffect(() => {
    const path = location.pathname;

    if (path.includes('/online')) {
      handleShowOnline();
    } else if (path.includes('/random')) {
      handleShowRandom();
    } else if (path.includes('/messages')) {
      handleMessages();
    }
  }, [location]);

  const handleSelectUser = (item) => {
    setSelectedUser(item);

    if (item.seen === 0 && messageStatus !== 0) {
      setMessageStatus(messageStatus - 1);

      setConversations((prevConversations) => {
        return prevConversations.map((conv) => {
          if (conv.id === item.id) {
            return {
              ...conv,
              seen: 1,
            };
          }
          return conv;
        });
      });
    }

    navigate('/current');
  };

  const handleDeleteCoversatin = (user_id) => {
    setConversations((prevConversations) => {
      // Filter out the conversation with the specified user_id
      const updatedConversations = prevConversations.filter(conv => conv.id !== user_id);
      return updatedConversations;
    });
    handleMessages(true);
  };

  const handleLogout = () => {
    if (socket) {
      socket.emit('logout', true);
      socket.close();
    } 
    setSelectedUser(null);
    onLogOut();
  };

  const applyGenderFilter = (gender) => {
    setGenderFilter(gender);
    setOnlineUserCount(users.length);
  };

  const handleShowOnline = () => {
    setSelectedUser(null);
    setShowOnline(true);
    setShowRandom(false);
    setShowMessages(false);
    setActiveButton('online');

    if (!showOnline) {
      navigate('/online');
    }
  };


  const handleMessages = (current = false) => {
    setActiveButton('messages');
    setSelectedUser(null);
    setShowMessages(true);
    setShowOnline(false);
    setShowRandom(false);

    if (showRandom) {
      socket.emit('randomDisconnect', true);
    }

    if (!showMessages || current) {
      navigate('/messages');
    }
  };

  const handleShowRandom = () => {
    setShowRandom(true);
    setShowOnline(false);
    setShowMessages(false);
    setSelectedUser(null);
    setActiveButton('random');

    if (!showRandom) {
      navigate('/random');
    }
  };

  const handleBack = () => {
    setShowOnline(false);
    setShowRandom(false);
    setShowMessages(false);
    setSelectedUser(null);
  };

  const handleBackConv = () => {
    if (socket && socket.connected) {
      socket.emit('getConversations', user);
    }
    setShowMessages(true);
    setSelectedUser(null);
  };

  
  const fetchUserData = () => {
    if (socket && socket.connected && !dataUpdateTime) {
      socket.emit('users', { user: user });
    }

    if (dataUpdateTime) {
      clearTimeout(dataUpdateTime);
    }

    const timint = setTimeout(fetchUserData, 10000);
    setDataUpdateTime(timint);
  
  };

  useEffect(() => {
    if (selectedUser === null) {
      const unseenCount = conversations.filter((conv) => conv.seen === 0).length;
      setMessageStatus(unseenCount);
    }
  }, [conversations, selectedUser]);

  useEffect(() => {
    if (socket) {
      socket.on('message', (msg) => {
        const blockedUsers = JSON.parse(localStorage.getItem('blockedUsers') || '[]');
        if (blockedUsers.includes(msg.users_id)) {
          return;
        }
        // dont touch it!!! 
        setNewMessage(msg);

        const userExists = conversations.some(conv => conv.id === msg.users_id);

        if (!userExists) {
          socket.emit('getConversations', user);
         
        } else { 
          setConversations((prevConversations) => {
            const updatedConversations = prevConversations.map((conv) => {
              if (conv.id === msg.users_id) {
                return {
                  ...conv,
                  seen: 0,
                };
              }
              return conv;
            });

            return updatedConversations;
          });
        }
        
        if (selectedUser !== msg.users_id) {
          const unseenCount = conversations.filter((conv) => conv.seen === 0).length;
          setMessageStatus(unseenCount);
        }

      });

      socket.on('logout', function () {
        handleLogout();
      });

      socket.on('version', (data) => {
        if (data.version !== VERSION) {
          setIsConnectionLost(true); // Set connection lost flag when disconnected
        }
      });

      socket.on('connect', function () {
        fetchUserData();
        socket.emit('getConversations', user);
      });

      socket.on('users', (data) => {
        setUsers(data);
        setOnlineUserCount(data.length);
      });

      socket.on('conversations', (convos) => {
        const blockedUsers = JSON.parse(localStorage.getItem('blockedUsers') || '[]');
        const filteredConversations = convos.filter(convo => !blockedUsers.includes(convo.id));
        setConversations(filteredConversations);
      });

      return () => {
        socket.off('message');
        socket.off('logout');
        socket.off('users');
        socket.off('conversations');
      };
    }
  }, [socket, selectedUser, conversations]);
  
  if (!user) {
    return;
  }

  return (
    <div className="welcome-container">
      <Header user={user} socket={socket} />
      <div className="rooms">
        <button
          className={`action-button ${activeButton === 'online' ? 'active' : ''}`}
          onClick={handleShowOnline}
        >
          <FaUsers /> <strong>{onlineUserCount}</strong>
        </button>
        <button
          className={`action-button ${activeButton === 'random' ? 'active' : ''}`}
          onClick={handleShowRandom}
        >
          <FaRandom />
        </button>
        <button
          className={`action-button ${activeButton === 'messages' ? 'active' : ''}`}
          onClick={handleMessages}
        >
          <IoMailUnread />
          {messageStatus !== 0 && <strong className="message-count">+{messageStatus}</strong>}
        </button>
      </div>
      {showOnline ? (
        <div>
          <span className="nav-title">Online Users</span>
          <div>
            {selectedUser ? (
              <div>
                <Messages
                  user={user}
                  selectedUser={selectedUser}
                  socket={socket}
                  setSelectedUser={setSelectedUser}
                  onBack={handleBackConv}
                  handleDeleteCoversatin={handleDeleteCoversatin}
                  newMessage={newMessage}
                />
              </div>
            ) : (
              <div className='gender-buttons'>
                <button className={`gender-filter ${genderFilter === 2 ? 'active' : ''}`} onClick={() => applyGenderFilter(2)}>
                  <FaMale /> Male
                </button>
                <button className={`gender-filter ${genderFilter === 1 ? 'active' : ''}`} onClick={() => applyGenderFilter(1)}>
                  <FaFemale /> Female
                </button>
                <button className={`gender-filter ${genderFilter === null ? 'active' : ''}`} onClick={() => applyGenderFilter(null)}>
                  All
                </button>
                <div className="users-list">
                  {users.filter(u => !genderFilter || u.gender === genderFilter).map((u) => (
                    user.id !== u.id && (
                      <div className="list" key={u.id}>
                        <div  onClick={() => handleSelectUser(u)}>
                          {u.online === 1 ? (
                            <div className="online"></div>
                          ) : (
                            <div className="offline"></div>
                          )}
                          {u.image ? (
                            <img className={`image ${u.review === 1 ? 'blur' : ''}`} src={`${BASE_URL}/up/${u.review === 1 ? 'blur/' : ''}${u.image}`} alt="profile" width="50" height="50" />
                          ) : (
                            <FaUserCircle className="image" />
                          )}
                          <h3 className={'gender_' + u.gender}>
                            {u.name}, {u.age}
                            <CountryFlag className="flag" countryCode={u.country} svg />
                          </h3>
                        </div>
                      </div>
                    )
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : showRandom ? (
        <div>
          <span className="nav-title">Random Chat</span>
          <RandomChat user={user} socket={socket} onBack={handleBack} />
        </div>
      ) : showMessages ? (
        <div>
          {selectedUser ? (
            <div>
              <span className="nav-title">Messages</span>
              <Messages
                user={user}
                selectedUser={selectedUser}
                socket={socket}
                setSelectedUser={setSelectedUser}
                onBack={handleBackConv}
                handleDeleteCoversatin={handleDeleteCoversatin}
                newMessage={newMessage}
              />
            </div>
          ) : (
            <div>
              <span className="nav-title">Messages</span>
              <Conversations user={user} conversations={conversations} onSelectConversation={handleSelectUser}  socket={socket} onBack={handleBackConv} handleDeleteCoversatin={handleDeleteCoversatin} />
            </div>
          )}
        </div>
      ) : (
          <BeforeStart handleShowOnline={handleShowOnline}/>
      )}
      
      <div style={{ textAlign: 'center' }}>
            <InstallButton /> 
      </div>
      
      <Footer user={user} onLogout={handleLogout} />

      {isConnectionLost && (
        <ConnectionLost/>
      )}
    </div>
  );
}

export default Welcome;
