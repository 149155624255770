// constants.js
const API_BASE_URL = 'https://ws.pogichat.com';
const BASE_URL = 'https://www.pogichat.com';
const VERSION = 6;
const LANGUAGES = [
  // European and Asian languages, sorted alphabetically
  "Albanian", "Arabic", "Armenian", "Azerbaijani", "Basque",
  "Belarusian", "Bengali", "Bosnian", "Bulgarian", "Burmese",
  "Catalan", "Chinese", "Croatian", "Czech", "Danish",
  "Dutch", "English", "Estonian", "Filipino", "Finnish",
  "French", "Galician", "Georgian", "German", "Greek",
  "Gujarati", "Hebrew", "Hindi", "Hungarian", "Icelandic",
  "Indonesian", "Irish", "Italian", "Japanese", "Kannada",
  "Kazakh", "Korean", "Kurdish", "Lao", "Latvian",
  "Lithuanian", "Luxembourgish", "Macedonian", "Malay", "Malayalam",
  "Maltese", "Marathi", "Mongolian", "Montenegrin", "Nepali",
  "Norwegian", "Pashto", "Persian", "Polish", "Portuguese",
  "Punjabi", "Romanian", "Russian", "Serbian", "Sinhala",
  "Slovak", "Slovenian", "Spanish", "Swedish", "Tamil",
  "Telugu", "Thai", "Tibetan", "Turkish", "Turkmen",
  "Ukrainian", "Urdu", "Uzbek", "Vietnamese", "Welsh"
];


// For Node.js
module.exports = {
  API_BASE_URL,
  BASE_URL,
  VERSION,
  LANGUAGES
}; 