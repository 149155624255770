import React from 'react';
import Footer from './Footer';
import HeaderNav from './HeaderNav';

function PrivacyPolicy() {
  
  return (
    <>
    <div className="welcome-container">
    <HeaderNav/> 

      <h2>Privacy Policy</h2>
      <p>
        This privacy policy outlines how we collect, use, and protect your
        information when you use our chat application, including the use of
        Google AdSense, Google Analytics, and Cloudflare. By using the application, you agree to the terms and
        practices described in this policy.
      </p>

      <h3>Information We Collect</h3>
      <p>
        When you use the chat application, we may collect the following types of
        information:
      </p>
      <ul>
        <li>Browser version: We collect information about your browser version for analytical purposes.</li>
        <li>IP addresses: We collect IP addresses for security and moderation purposes.</li> 
      </ul>

      <h3>Our Pledge to Protecting Minors' Privacy</h3>
      <p>
        We are steadfast in our commitment to safeguarding the privacy of children. In compliance with The Children’s Online Privacy Protection Act ("COPPA"), we ensure strict adherence to its mandate that online service providers must secure parental consent prior to the collection of personally identifiable information from children under the age of 13.
      </p>
      <p>
        This commitment is uniformly enforced across our platform and is not subject to variation based on differing consent requirements that may exist in various jurisdictions. We do not knowingly engage in the collection or solicitation of personally identifiable information from children under the age of 13. In the event that we become aware of any personal information that has been gathered from a child under 13 without verification of parental consent, we will take immediate action to remove such information from our databases.
      </p>
      <p>
        If you are a parent or guardian and suspect that your child under 13 has provided personal information to our platform, we urge you to reach out to us without delay via our contact form. We are committed to working with you to protect your child’s privacy and ensure the prompt resolution of your concerns.
      </p>

      <h3>Use of Google AdSense</h3>
      <p>
        Our chat application uses Google AdSense to display advertisements. AdSense is a third-party advertising service provided by Google. It may use cookies and similar technologies to collect information about your browsing behavior and interests to display relevant ads.
      </p>
      <p>
        You can learn more about how Google collects and uses data through AdSense by visiting their <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer">Privacy & Terms</a> page.
      </p>

      <h3>Use of Google Analytics</h3>
      <p>
        We use Google Analytics to collect information about how you use our application. Google Analytics collects information such as how often users visit the application, what pages they visit, and what other sites they used prior to coming to our application. We use the information we get from Google Analytics to improve our application.
      </p>
      <p>
        Google Analytics collects only the IP address assigned to you on the date you visit the application, rather than your name or other identifying information. We do not combine the information collected through the use of Google Analytics with personally identifiable information. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit the application, the cookie cannot be used by anyone but Google. Google's ability to use and share information collected by Google Analytics about your visits to this application is restricted by the <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Privacy Policy</a>.
      </p>
      <p>
        You can prevent Google Analytics from recognizing you on return visits to this application by disabling cookies on your browser.
      </p>

      <h3>Use of Cloudflare</h3>
      <p>
        We use Cloudflare to enhance the security and performance of our application. Cloudflare may collect information such as your IP address and browser configuration, which is used to detect malicious activity and provide a safe browsing experience. Cloudflare's data collection and usage are governed by their <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
      </p>

      <h3>How We Use Your Information</h3>
      <p>
        We use the collected information for the following purposes:
      </p>
      <ul>
        <li>To provide and maintain the chat application.</li>
        <li>To personalize your experience and improve our services and features.</li>
        <li>To monitor and moderate the chat application and enforce our terms and conditions.</li>
        <li>To analyze usage trends and improve the application's performance.</li>
        <li>To display relevant ads through Google AdSense based on your browsing behavior and interests.</li>
      </ul>

      <h3>Information Sharing and Disclosure</h3>
      <p>
        We do not share your information with any third parties unless required by law or to protect our legal rights.
      </p> 

      <h3>Data Security</h3>
      <p>
        We take appropriate measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is completely secure.
      </p>

      <h3>Changes to This Privacy Policy</h3>
      <p>
        We may update this privacy policy from time to time. Any changes will be posted on this page, and the revised policy will take effect when posted.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or concerns about this privacy policy, please contact us via our contact form.
      </p>

      <Footer />
    </div>
    </>
  );
}

export default PrivacyPolicy;
