import React from 'react';
import Footer from './Footer';
import HeaderNav from './HeaderNav';

function CookiePolicy() {
  return (
    <>
    <div className="welcome-container">
  
    <HeaderNav/>
      <h2>Cookie Policy</h2>
      <p>
        This cookie policy explains how we use cookies and similar technologies
        on our chat application. By using the application, you consent to the
        use of cookies in accordance with this policy.
      </p>
      <p>
        <h3>What are Cookies:</h3>
      </p>
      <p>
        Cookies are small text files that are stored on your device when you
        visit a website or use an application. They are widely used to make
        websites and applications work more efficiently and to provide
        information to the website or application owners.
      </p>
      <p>
        <h3>How We Use Cookies:</h3>
      </p>
      <p>
        We use cookies and similar technologies for the following purposes:
      </p>
      <ul>
        <li>
          <h3>Essential Cookies:</h3> These cookies are necessary for
          the operation of the chat application and enable basic functions such
          as user authentication and security.
        </li>
        <li>
          <h3>Functionality Cookies:</h3> These cookies allow the chat
          application to remember your preferences and provide enhanced
          features and personalization.
        </li>
        <li>
          <h3>Analytical Cookies:</h3> These cookies collect information
          about how you use the chat application, such as the pages you visit
          and any errors you encounter. This helps us analyze and improve the
          performance of the application.
        </li>
        <li>
          <h3>Third-Party Cookies:</h3> We may also allow third-party
          cookies, such as those used by Google AdSense, to be placed on the
          chat application to provide personalized advertisements and
          analytics. Please refer to the respective third-party privacy policies
          for more information on their use of cookies.
        </li>
      </ul>
      <p>
        <h3>Managing Cookies:</h3>
      </p>
      <p>
        Most web browsers allow you to manage your cookie preferences. You can
        typically set your browser to refuse cookies or to alert you when
        cookies are being sent. However, please note that blocking or deleting
        cookies may impact the functionality and performance of the chat
        application.
      </p>
      <p>
        <h3>Changes to This Cookie Policy:</h3>
      </p>
      <p>
        We may update this cookie policy from time to time. Any changes will be
        posted on this page, and the revised policy will take effect when
        posted.
      </p>
      <p>
        <h3>Contact Us:</h3>
      </p>
      <p>
        If you have any questions or concerns about this cookie policy, please
        contact us using contact form.
      </p>

       <Footer/>
    </div>

    </>
  );
}

export default CookiePolicy;
