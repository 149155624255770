import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { API_BASE_URL, BASE_URL, LANGUAGES } from '../contexts/Constants';
import { FaUserCircle } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import GenderIcon from './GenderIcon';
import { setSession } from '../services/auth';

const EditUserPopup = forwardRef(({ onClose, user, profileImage, initialIsBlurred, socket, onUpdate }, ref) => {
  const [userName, setUserName] = useState(user.name);
  const [age, setAge] = useState(user.age);
  const [gender, setGender] = useState(user.gender);
  const [localProfileImage, setLocalProfileImage] = useState(profileImage);
  const [isBlurred, setIsBlurred] = useState(user.review === 1);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [here, setHere] = useState(user.here);
  const [languages, setLanguages] = useState(user.languages ? user.languages.split(', ') : []);
  const [newLanguage, setNewLanguage] = useState("");
  const fileInputRef = useRef(null);

  useEffect(() => {
    setLocalProfileImage(profileImage);
    setIsBlurred(initialIsBlurred);
  }, [profileImage, initialIsBlurred]);

  const handleUpdate = async () => {
    setError(null);
    if (!userName || !age || !gender) {
      setError('All fields are required.');
      return;
    }

    const userData = {
      id: user.id,
      name: userName,
      age: age,
      gender: gender,
      here: here,
      languages: languages.join(', '),
      sessionId: user.sessionId
    };

    try {
      const response = await fetch(`${API_BASE_URL}/signin/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const jsonData = await response.json();

      if (jsonData.success) {
        setError(null);
        onClose();
        user.name = userData.name;
        user.age = userData.age;
        user.gender = userData.gender;
        user.here = userData.here;
        user.languages = userData.languages;
        setSession(user);
      } else {
        setError(jsonData.error);
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  const handleFileChange = async (event) => {
    setError(null);
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    const formData = new FormData();
    formData.append('image', file);
    formData.append('user', JSON.stringify(user));

    try {
      const response = await fetch(`${API_BASE_URL}/profile/image`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      setIsUploading(false);
      if (data.success) {
        onUpdate(data.image, data.review);
        setLocalProfileImage(data.image);
        setIsBlurred(data.review === 1);
        socket.emit('updateImage', { id: user.id, sessionId: user.sessionId });
      } else {
        throw new Error(data.error || 'Failed to upload');
      }
    } catch (error) {
      setError('Failed to upload: ' + error.message);
      setIsUploading(false);
    }
  };

  const handleAddLanguage = () => {
    if (newLanguage && !languages.includes(newLanguage)) {
      if (languages.length < 5) {
        setLanguages([...languages, newLanguage]);
        setNewLanguage("");
      } else {
        setError('You can add up to 5 languages only.');
      }
    }
  };

  const handleRemoveLanguage = (language) => {
    setLanguages(languages.filter(lang => lang !== language));
  };

  return (
    <div id="edit-user-popup" className="edit-user-popup" ref={ref}>
      <div>
        <h3>Edit <GenderIcon gender={user.gender} /> {user.name}, {user.age} age</h3>

        {error && <div className="error-message">{error}</div>}
        <div className="profile-picture-container">

          <button onClick={() => fileInputRef.current.click()}>Add Image</button>
          {localProfileImage ? (
            <>
              <img
                src={`${BASE_URL}/up/${isBlurred ? 'blur/' : ''}${localProfileImage}`}
                alt="Profile"
                className={`profile-picture ${isBlurred ? 'blur' : ''}`}
                onContextMenu={(e) => e.preventDefault()}
              />
              {isBlurred && <div className="reviewing-text">Reviewing...</div>}
            </>
          ) : (
            <>
              <FaUserCircle className="profile-icon" />
            </>
          )}

          {isUploading && <div>Uploading...</div>}
        </div>

        <div>
          <label>User Name:</label>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div>
          <label>Age:</label>
          <select
            value={age}
            onChange={(e) => setAge(e.target.value)}
            required
          >
            {Array.from({ length: (80 - 18) }, (_, i) => 18 + i).map((age) => (
              <option key={age} value={age}>
                {age}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Gender:</label>
          <select value={gender} onChange={(e) => setGender(e.target.value)}>
          <option value="0">-- Not selected --</option>
            <option value="2">Male</option>
            <option value="1">Female</option>
          </select>
        </div>
        <div>
          <label>Here For:</label>
          <select value={here} onChange={(e) => setHere(e.target.value)}>
            <option value="0">-- Not selected --</option>
            <option value="1">Flirt</option>
            <option value="2">Clean Chat</option>
            <option value="3">Language Exchange</option>
          </select>
        </div>
        <div>
          <label>Speaking Languages:</label>
          <div className="languages-container">
            {languages.map((lang, index) => (
              <div key={index} className="language-tag">
                {lang}
                <AiFillCloseCircle
                  className="remove-icon"
                  onClick={() => handleRemoveLanguage(lang)}
                />
              </div>
            ))}
          </div>
          <div className="language-selection">
            <select value={newLanguage} onChange={(e) => setNewLanguage(e.target.value)}>
              <option value="" disabled>Select a language</option>
              {LANGUAGES.map((language) => (
                <option key={language} value={language}>
                  {language.charAt(0).toUpperCase() + language.slice(1)}
                </option>
              ))}
            </select>
            <button type="button" onClick={handleAddLanguage}>Add</button>
          </div>
        </div>
        <button className="update-button" onClick={handleUpdate}>Save Changes</button>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
});

export default EditUserPopup;
