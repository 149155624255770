import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import SignIn from './components/SignIn';
import Welcome from './components/Welcome';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookiePolicy from './components/CookiePolicy';
import Articles from './components/Articles';
import Article from './components/Article';
import { getSession, logout, setSession } from './services/auth'; 
import ReactGA from 'react-ga4';  

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-8MHBY69Q7Y');
    const storedUser = getSession();
    if (storedUser) {
      setUser(storedUser);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  const handleLogIn = (data) => {
    const { sessionId, id, name, gender, age , here, languages} = data;
    const user = { sessionId, id, name, gender, age , here, languages};
    setUser(user);
    setSession(user);

    // Track the login event with Google Analytics 4
    ReactGA.event({
      category: 'User',
      action: 'Login',
      label: 'Google'
    });
  };

  const handleLogOut = () => {
    logout();
    setUser(null);

    // Track the logout event with Google Analytics 4
    ReactGA.event({
      category: 'User',
      action: 'Logout',
      label: 'Google'
    });

    setTimeout(() => {
      // Redirect to the main page
      window.location.href = '/';
    }, 300);
  };

  return (
    <Routes>
      <Route path="/" element={
        <div>
          {loading ? (
            <div>Loading...</div>
          ) : user ? (
            <Welcome user={user} onLogOut={handleLogOut} />
          ) : (
            <SignIn onSignIn={handleLogIn} />
          )}
        </div>
      } />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/online" element={<Welcome user={user} onLogOut={handleLogOut} />} />
      <Route path="/random" element={<Welcome user={user} onLogOut={handleLogOut} />} />
      <Route path="/messages" element={<Welcome user={user} onLogOut={handleLogOut} />} />
      <Route path="/current" element={<Welcome user={user} onLogOut={handleLogOut} />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/articles/:slug" element={<Article />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
