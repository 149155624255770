import React, { useEffect, useRef, useState } from 'react'; 
import { API_BASE_URL, BASE_URL} from '../contexts/Constants.js';
import { FaUserCircle} from 'react-icons/fa'; // Import the default profile icon from React Icons 
import EditUserPopup from './EditUserPopup'; // Adjust the path as needed
 
function Header({ user, socket }) { 
  const  [showEditPopup, setshowEditPopup] =  useState(false);
  const popupRef = useRef(null);
  const [profileImage, setProfileImage] = useState(null);
  const [error, setError] = useState(null);
  const [isBlurred, setIsBlurred] = useState(false); // Add a state variable to track blur 
  
  useEffect(() => {
    // Fetch the user's profile image from the server when the component mounts
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/profile/get_image/${user.id}`);
        if (!response.ok) {
          setError('Failed to fetch profile.');
          return;
        }

        const data = await response.json();
        if (data.image && data.image !== null) {
          setProfileImage(data.image);
           
          // Set isBlurred to true when data.review === 1
          if (data.review === 1) {
            setIsBlurred(true);
          }
        }
      } catch (error) {
        console.error('Failed to fetch profile image:', error);
      }
    };

    fetchProfile();
  }, [user.id]);

  const handleEditProfile =() => {
    setshowEditPopup(!showEditPopup);
  } 

  return ( 
    <>
      {showEditPopup && (
        <div className="popup-container">
          <div className="popup-content" ref={popupRef}>
            <button className="back-button" onClick={() => setshowEditPopup(false)}>
              Back
            </button>
              
            <EditUserPopup
              onClose={() => setshowEditPopup(false)}
              user={user} 
              socket={socket}
              profileImage={profileImage}
              initialIsBlurred={isBlurred}
              onUpdate={(image, review) => {
                setProfileImage(image);
                setIsBlurred(review === 1);
              }}
            />

          </div>
        </div>
      )} 
      
      <img className="logo_inside" src="logo.png" alt="Logo" height={51} />
    
      
      <div className="user-info">  
          <div className="profile-picture-container" onClick={handleEditProfile}>
            {profileImage ? (
              <>
              <img
                src={`${BASE_URL}/up/${isBlurred ? 'blur/' : ''}${profileImage}`} // Construct the image URL
                alt="Profile"
                className={`profile-picture ${isBlurred ? 'blur' : ''}`}  
                onContextMenu={(e) => e.preventDefault()} // Disable right-click context menu
                />
              </>
            ) : ( 
              <>
                <FaUserCircle className="profile-icon"/>  
              </>
            )}  
            <div className="user-name">{user.name}, {user.age}</div> 

          
        </div>
  
        {error && <div className="error-message">{error}</div>}
      </div> 
      </>

  );
}

export default Header;
