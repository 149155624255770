import React from 'react';  
import {FaVenus, FaMars } from 'react-icons/fa'; // Import the default profile icon from React Icons 
function GenderIcon({ gender }) { 
    gender = parseInt(gender);
    switch (gender) {
    case 1: // Female
        return <FaVenus className="gender-icon" style={{ color: 'pink' }} />;
    case 2: // Male
        return <FaMars className="gender-icon" style={{ color: '#b8b8ff' }} />;
    default:
        return null; // No icon for undefined or other genders
    } 

}


export default GenderIcon;